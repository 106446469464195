import {
  faCopy,
  faPlus,
  faThumbtack,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import ReactTooltip from "react-tooltip";
import { PinLinkRequest, UnShareLinkRequest } from "../../../common/requests";
import { LinkType } from "../../../common/types";
import { SocketClient } from "../common/SocketClient";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface Props {
  collapsed: boolean;
  socket: SocketClient;
  onFocus: Function;
  focused: boolean;
  name: string;
  link: string;
  onSave: Function;
  pinnedLinks: { link: string; hashedUserToken: string }[];
  savedLinks: { link: string }[];
}

interface State {}
export default class SharedItem extends React.PureComponent<Props, State> {
  onDelete = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const request: UnShareLinkRequest = { link: this.props.link };
    this.props.socket.emit("unShareLink", request);
  };

  onSave = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onSave(this.props.name, this.props.link);
  };

  onPin = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const request: PinLinkRequest = {
      name: this.props.name,
      link: this.props.link,
    };
    this.props.socket.emit("pinLink", request);
  };

  onFocus = () => {
    const linkType: LinkType = "shared";
    this.props.onFocus(this.props.link, linkType);
  };

  render() {
    return (
      <div
        onClick={this.onFocus}
        className={`namedLinkContainer ${this.props.focused && "focused"}`}
      >
        <ReactTooltip effect="solid" />
        <span
          className="createdItemText"
          data-tip={`${this.props.name} (${this.props.link})`}
        >
          {this.props.name}
        </span>
        {!this.props.collapsed && 
        <div
          className="buttonBar"
          style={{
            width: "60%",
            marginRight: "10px",
            marginLeft: "15%",
            display: "inline-flex",
          }}
        >
          <MDBBtn
            data-tip="Save"
            onClick={(e: MouseEvent) => this.onSave(e)}
            className="menuBtn"
            disabled={_.some(
              this.props.savedLinks,
              (l) => l.link === this.props.link
              )}
              >
            <FontAwesomeIcon icon={faPlus} />
          </MDBBtn>
          <MDBBtn
            data-tip="Pin"
            onClick={(e: MouseEvent) => this.onPin(e)}
            className="menuBtn"
            disabled={_.some(
              this.props.pinnedLinks,
              (l) => l.link === this.props.link
              )}
              >
            <FontAwesomeIcon icon={faThumbtack} />
          </MDBBtn>
          <CopyToClipboard text={this.props.link}>
            <MDBBtn data-tip="Copy Link" className="menuBtn">
              <FontAwesomeIcon icon={faCopy} />
            </MDBBtn>
          </CopyToClipboard>
          <MDBBtn
            data-tip="Delete"
            onClick={(e: MouseEvent) => this.onDelete(e)}
            className={"menuBtn"}
            >
            <FontAwesomeIcon icon={faTrash} />
          </MDBBtn>
        </div>
          }
      </div>
    );
  }
}
