import { MDBSpinner } from "mdb-react-ui-kit";
import React from "react";

export default class Loading extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "50vh",
        }}
      >
        <span>Contacting server...</span>
        <br />
        <MDBSpinner role="status" />
      </div>
    );
  }
}
