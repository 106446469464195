import io from "socket.io-client";
import * as uuid from "uuid";

export class SocketClient {
  private socket: SocketIOClient.Socket;
  constructor() {
    let token = localStorage.getItem("token");
    if (token === null) {
      token = uuid.v4();
      localStorage.setItem("token", token);
    }

    const productionBackendURL = window.location.hostname.includes("pr")
      ? (process.env.REACT_APP_PREVIEW_BACKEND_URL as string)
      : (process.env.REACT_APP_BACKEND_URL as string);
    if (!productionBackendURL) {
      console.log("Backend URL env not suppplied, connecting to localhost");
    }
    this.socket = io(
      process.env.NODE_ENV === "production"
        ? productionBackendURL
        : `${window.location.protocol}//${window.location.hostname}:4035`,
      {
        auth: { token },
      }
    );
  }

  on(eventNamespace: string, fn: (...args: any | undefined) => void) {
    this.socket.on(eventNamespace, fn);
  }

  off(eventNamespace: string, fn?: Function) {
    this.socket.off(eventNamespace, fn);
  }

  emit(eventNamespace: string, data?: any) {
    this.socket.emit(eventNamespace, data);
  }
}
