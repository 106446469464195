import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faShareSquare,
  faThumbtack,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { PinLinkRequest, ShareLinkRequest } from "../../../common/requests";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { LinkType, LocalStorageLinkItem } from "../../../common/types";
import { SocketClient } from "../common/SocketClient";
import CopyToClipboard from "react-copy-to-clipboard";
interface Props {
  collapsed: boolean;
  socket: SocketClient;
  onFocus: Function;
  onDelete: Function;
  focused: boolean;
  name: string;
  link: string;
  sharedLinks: string[];
  pinnedLinks: string[];
}

interface State {}
export default class SavedItem extends React.PureComponent<Props, State> {
  onDelete = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const links: LocalStorageLinkItem[] = JSON.parse(
      //@ts-ignore
      localStorage.getItem("links")
    );
    const newLinks = _.filter(links, (l) => l.link !== this.props.link);

    localStorage.setItem("links", JSON.stringify(newLinks));
    this.props.onDelete(this.props.name);
  };

  onPin = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const request: PinLinkRequest = {
      name: this.props.name,
      link: this.props.link,
    };
    this.props.socket.emit("pinLink", request);
  };

  onShare = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const request: ShareLinkRequest = {
      name: this.props.name,
      link: this.props.link,
    };
    this.props.socket.emit("shareLink", request);
  };

  onFocus = () => {
    const linkType: LinkType = "saved";
    this.props.onFocus(this.props.link, linkType);
  };

  render() {
    return (
      <div
        onClick={this.onFocus}
        className={`namedLinkContainer ${this.props.focused && "focused"}`}
      >
        <ReactTooltip effect="solid" />
        <span
          className="createdItemText"
          data-tip={`${this.props.name} (${this.props.link})`}
        >
          {this.props.name}
        </span>
        {!this.props.collapsed && 
        <div
          className="buttonBar"
          style={{
            width: "60%",
            marginRight: "10px",
            marginLeft: "15%",
            display: "inline-flex",
          }}
        >
          <MDBBtn
            data-tip="Share"
            onClick={(e: MouseEvent) => this.onShare(e)}
            className="menuBtn"
            disabled={this.props.sharedLinks.includes(this.props.link)}
            >
            <FontAwesomeIcon icon={faShareSquare} />
          </MDBBtn>
          <MDBBtn
            data-tip="Pin"
            disabled={this.props.pinnedLinks.includes(this.props.link)}
            onClick={(e: MouseEvent) => this.onPin(e)}
            className="menuBtn"
            >
            <FontAwesomeIcon icon={faThumbtack} />
          </MDBBtn>
          <CopyToClipboard text={this.props.link}>
            <MDBBtn data-tip="Copy Link" className="menuBtn">
              <FontAwesomeIcon icon={faCopy} />
            </MDBBtn>
          </CopyToClipboard>
          <MDBBtn
            data-tip="Delete"
            onClick={(e: MouseEvent) => this.onDelete(e)}
            className="menuBtn"
            >
            <FontAwesomeIcon icon={faTrash} />
          </MDBBtn>
        </div>
        }
      </div>
    );
  }
}
