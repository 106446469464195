import "./App.css";
import * as uuid from "uuid";
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import React from "react";
import LiveView from "./components/pages/LiveView";
import { SocketClient } from "./common/SocketClient";
import { SetHashedUserTokenResponse } from "../../common/responses";

interface State {
  socket: SocketClient;
}

interface Props {}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { socket: new SocketClient() };

    this.state.socket.on(
      "hashedUserToken",
      (res: SetHashedUserTokenResponse) => {
        localStorage.setItem("hashedUserToken", res.hashedUserToken);
      }
    );
  }
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/:slug">
            <LiveView socket={this.state.socket} />
          </Route>
          <Redirect from="/" to={{ pathname: uuid.v4() }} />
        </Switch>
        ;
      </Router>
    );
  }
}

export default App;
