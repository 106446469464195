import React from "react";

interface Props {
  link?: string;
  sidebarCollapsed: boolean;
}

interface State {
  iframeWidth: number;
}
export default class VirtualTab extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { iframeWidth: this.CalcIframeWidth() };
  }

  componentDidUpdate = () => {
    this.handleResize();
  };

  componentDidMount = () => {
    this.handleResize()
    window.addEventListener("resize", this.handleResize);
  };
  handleResize = () => {
    this.setState({ iframeWidth: this.CalcIframeWidth() });
  };

  SidebarWidth = () => {
    const sidebarWidth = document.getElementById("sidebar")?.clientWidth ?? 0;
    return sidebarWidth;
  };

  CalcIframeWidth = () => {
    return window.innerWidth - this.SidebarWidth() ;
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "lightblue",
          display: "inline-flex",
          position: "fixed",
          top: "0px",
          width: "100%",
          height: "100%",
        }}
      >
        <iframe
          title="Virtual Tab"
          width={this.state.iframeWidth + "px"}
          id="test"
          src={this.props.link}
        ></iframe>
      </div>
    );
  }
}
