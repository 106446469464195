import React from "react";
import LinkInput from "./LinkInput";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import SavedItem from "./SavedItem";
import {
  GetStateResponse,
  PinLinkResponse,
  ShareLinkResponse,
  UnPinLinkResponse,
  UnShareLinkResponse,
} from "../../../common/responses";
import SharedItem from "./SharedItem";
import PinnedItem from "./PinnedItem";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faHardHat,
  faShare,
  faStickyNote,
  faThumbtack,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import PasswordInput from "./PasswordInput";
import { LinkType, LocalStorageLinkItem } from "../../../common/types";
import { SocketClient } from "../common/SocketClient";
import ReactTooltip from "react-tooltip";
import { Link } from "../common/types";
import { MDBBtn } from "mdb-react-ui-kit";
import { GetStateRequest } from "../../../common/requests";
import { DOCUMENTATION_URL } from "../common/constants";

interface State {
  sideBarCollapsed: boolean;
  savedLinks: { dateAdded: Date; name: string; link: string }[];
  pinnedLinks: {
    dateAdded: Date;
    name: string;
    link: string;
    hashedUserToken: string;
  }[];
  sharedLinks: {
    dateAdded: Date;
    name: string;
    link: string;
    hashedUserToken: string;
  }[];
  savedLinksMenuOpen: boolean;
  sharedLinksMenuOpen: boolean;
  pinnedLinksMenuOpen: boolean;
  documentationMenuOpen: boolean;
}

interface Props {
  socket: SocketClient;
  onCollapse: Function;
  onLinkUnshared: Function;
  onAdminVerified: Function;
  adminVerified: boolean;
  focusedLink?: { type?: LinkType; link: string };
  slug: string;
  connectedHashedUserTokens: string[];
  savedLinks: {
    dateAdded: Date;
    name: string;
    link: string;
  }[];
  pinnedLinks: Link[];
  sharedLinks: Link[];
  onFocus: Function;
}

export default class Sidebar extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sideBarCollapsed: false,
      savedLinks: this.props.savedLinks.map((link) => {
        return { ...link, focused: false };
      }),
      sharedLinks: this.props.sharedLinks.map((link) => {
        return { ...link, focused: false };
      }),
      pinnedLinks: this.props.pinnedLinks.map((link) => {
        return { ...link, focused: false };
      }),
      pinnedLinksMenuOpen: false,
      savedLinksMenuOpen: false,
      sharedLinksMenuOpen: false,
      documentationMenuOpen: true,
    };
  }

  componentDidMount = () => {
    const shouldOpenSavedMenu = this.props.savedLinks.length > 0;
    const shouldOpenSharedMenu = this.props.sharedLinks.length > 0;
    const shouldOpenPinnedMenu = this.props.pinnedLinks.length > 0;
    this.setState({
      savedLinksMenuOpen: shouldOpenSavedMenu,
      sharedLinksMenuOpen: shouldOpenSharedMenu,
      pinnedLinksMenuOpen: shouldOpenPinnedMenu,
    });

    this.props.socket.on("shareLink", this.linkShared);
    this.props.socket.on("pinLink", this.linkPinned);

    this.props.socket.on("linkUnShared", this.onDeleteSharedLink);
    this.props.socket.on("linkUnPinned", this.onDeletePinnedLink);
    this.props.socket.on("getState", (res: GetStateResponse) => {
      console.log(res.rooms);
    });
  };

  onAdminVerified = () => {
    this.props.onAdminVerified();
  };

  linkShared = (res: ShareLinkResponse) => {
    this.onFocus(res.link, "shared");
    this.setState({
      sharedLinks: [
        {
          dateAdded: res.dateAdded,
          name: res.name,
          link: res.link,
          hashedUserToken: res.hashedUserToken,
        },
        ...this.state.sharedLinks,
      ],
      sharedLinksMenuOpen: true,
    });
  };

  linkPinned = (res: PinLinkResponse) => {
    this.setState({
      pinnedLinks: [
        {
          dateAdded: res.dateAdded,
          name: res.name,
          link: res.link,
          hashedUserToken: res.hashedUserToken,
        },
        ...this.state.pinnedLinks,
      ],
      pinnedLinksMenuOpen: true,
    });
  };

  logState = () => {
    const adminPassword = localStorage.getItem("adminPassword") ?? "";
    const request: GetStateRequest = { adminPassword };
    this.props.socket.emit("getState", request);
  };

  onDeleteSavedLink = (name: string) => {
    this.setState(
      {
        savedLinks: _.filter(this.state.savedLinks, (l) => l.name !== name),
      },
      () => {
        this.setState({
          savedLinksMenuOpen: this.state.savedLinks.length > 0,
        });
      }
    );
  };

  onDeletePinnedLink = (res: UnPinLinkResponse) => {
    this.setState(
      {
        pinnedLinks: _.remove(
          this.state.pinnedLinks,
          (l) => l.link !== res.link
        ),
      },
      () => {
        this.setState({
          pinnedLinksMenuOpen: this.state.pinnedLinks.length > 0,
        });
      }
    );
  };

  onDeleteSharedLink = (res: UnShareLinkResponse) => {
    this.props.onLinkUnshared(res.defaultLink);
    this.setState(
      {
        sharedLinks: _.remove(
          this.state.sharedLinks,
          (l) => l.link !== res.link
        ),
      },
      () => {
        this.setState({
          sharedLinksMenuOpen: this.state.sharedLinks.length > 0,
        });
      }
    );
  };

  onFocus = (link: string, linkType: LinkType) => {
    this.props.onFocus(link, linkType);
  };

  onSave = (name: string, link: string) => {
    const savedLinks: LocalStorageLinkItem[] =
      //@ts-ignore
      JSON.parse(localStorage.getItem("links")) ?? [];
    savedLinks.push({ dateAdded: new Date(), name, link });
    localStorage.setItem("links", JSON.stringify(savedLinks));
    this.setState({
      savedLinks: [
        { dateAdded: new Date(), name, link },
        ...this.state.savedLinks,
      ],
      savedLinksMenuOpen: true,
    });
    this.onFocus(link, "saved");
  };

  collapseToggle = () => {
    this.props.onCollapse();
    this.setState({ sideBarCollapsed: !this.state.sideBarCollapsed });
  };

  render() {
    return (
      <div
        id="sidebar"
        style={{
          height: "100vh",
          display: "inline-flex",
        }}
      >
        <ReactTooltip effect="solid" />
        <div
          className="sideBarToggle"
          style={{
            cursor: "pointer",
            zIndex: 9999,
            position: "fixed",
            left: "2px",
            top: "2px",
          }}
          onClick={this.collapseToggle}
        >
          <div>
            <FontAwesomeIcon
              style={{
                width: "100%",
                marginLeft: "10px",
                paddingRight: this.state.sideBarCollapsed ? "10px" : "230px",
                marginTop: "10px",
              }}
              size={"2x"}
              icon={this.state.sideBarCollapsed ? faAngleRight : faAngleLeft}
            />
          </div>
        </div>
        <ProSidebar
          style={{ transition: "none" }}
          collapsedWidth={50}
          width={350}
          collapsed={this.state.sideBarCollapsed}
        >
          <SidebarContent>
            {!this.state.sideBarCollapsed && (
              <Menu iconShape="square">
                <MenuItem>
                  <LinkInput
                    socket={this.props.socket}
                    onSave={this.onSave}
                    savedLinks={this.state.savedLinks}
                    sharedItems={this.state.sharedLinks}
                    pinnedLinks={this.state.pinnedLinks}
                  />
                </MenuItem>
              </Menu>
            )}
            <Menu
              iconShape="circle"
              style={{
                marginTop: this.state.sideBarCollapsed ? "50px" : "10px",
              }}
            >
              <SubMenu
                className={
                  this.props.focusedLink?.type === "documentation"
                    ? "focused empty-links"
                    : "empty-links"
                }
                onClick={() => {
                  this.onFocus(DOCUMENTATION_URL, "documentation");
                }}
                style={{
                  marginLeft: this.state.sideBarCollapsed ? "-13px" : "inherit",
                }}
                data-tip={
                  this.state.sideBarCollapsed ? "How to use Link Hub" : ""
                }
                title={this.state.sideBarCollapsed ? "" : "How to use Link Hub"}
                icon={<FontAwesomeIcon icon={faStickyNote} />}
                open={false}
                id="documentation"
              ></SubMenu>
              <SubMenu
                data-tip={
                  this.state.sideBarCollapsed &&
                  this.state.pinnedLinks.length === 0
                    ? "No pinned links"
                    : ""
                }
                className={
                  this.state.pinnedLinks.length === 0 ? "empty-links" : ""
                }
                style={{
                  marginLeft: this.state.sideBarCollapsed ? "-13px" : "inherit",
                }}
                open={this.state.pinnedLinksMenuOpen}
                onOpenChange={() =>
                  this.setState({
                    pinnedLinksMenuOpen: !this.state.pinnedLinksMenuOpen,
                  })
                }
                icon={
                  <>
                    {/* <span className="fa-layers fa-x fa-fw"> */}
                    <FontAwesomeIcon icon={faThumbtack} />
                    {/* <span className="fa-layers-counter"></span> */}
                    {/* </span> */}
                  </>
                }
                key="pinned"
                title={this.state.sideBarCollapsed ? "" : "Pinned Links"}
              >
                {_.orderBy(this.state.pinnedLinks, ["dateAdded"], "desc").map(
                  (i) => {
                    return (
                      <PinnedItem
                        collapsed={this.state.sideBarCollapsed}
                        adminVerified={this.props.adminVerified}
                        hashedUserToken={i.hashedUserToken}
                        socket={this.props.socket}
                        focused={
                          this.props.focusedLink?.type === "pinned" &&
                          this.props.focusedLink.link === i.link
                        }
                        savedLinks={this.state.savedLinks}
                        onSave={this.onSave}
                        onFocus={this.onFocus}
                        key={`pinned-${i.name}-${i.link}`}
                        sharedLinks={this.state.sharedLinks.map((l) => {
                          return {
                            link: l.link,
                            hashedUserToken: l.hashedUserToken,
                          };
                        })}
                        name={i.name}
                        link={i.link}
                      />
                    );
                  }
                )}
              </SubMenu>
              <SubMenu
                data-tip={
                  this.state.sideBarCollapsed &&
                  this.state.sharedLinks.length === 0
                    ? "No shared links"
                    : ""
                }
                className={
                  this.state.sharedLinks.length === 0 ? "empty-links" : ""
                }
                style={{
                  marginLeft: this.state.sideBarCollapsed ? "-13px" : "inherit",
                }}
                key="shared"
                open={this.state.sharedLinksMenuOpen}
                onOpenChange={() =>
                  this.setState({
                    sharedLinksMenuOpen: !this.state.sharedLinksMenuOpen,
                  })
                }
                icon={<FontAwesomeIcon icon={faShare} />}
                title={this.state.sideBarCollapsed ? "" : "Shared Links"}
              >
                {_.orderBy(this.state.sharedLinks, ["dateAdded"], "desc").map(
                  (i) => {
                    return (
                      <SharedItem
                        collapsed={this.state.sideBarCollapsed}
                        socket={this.props.socket}
                        onSave={this.onSave}
                        savedLinks={this.state.savedLinks}
                        key={`shared-${i.name}-${i.link}`}
                        onFocus={this.onFocus}
                        focused={
                          this.props.focusedLink?.type === "shared" &&
                          this.props.focusedLink.link === i.link
                        }
                        pinnedLinks={this.state.pinnedLinks.map((l) => {
                          return {
                            link: l.link,
                            hashedUserToken: l.hashedUserToken,
                          };
                        })}
                        name={i.name}
                        link={i.link}
                      />
                    );
                  }
                )}
              </SubMenu>
              <SubMenu
                data-tip={
                  this.state.sideBarCollapsed &&
                  this.state.savedLinks.length === 0
                    ? "No saved links"
                    : ""
                }
                className={
                  this.state.savedLinks.length === 0 ? "empty-links" : ""
                }
                style={{
                  marginLeft: this.state.sideBarCollapsed ? "-13px" : "inherit",
                }}
                open={this.state.savedLinksMenuOpen}
                onOpenChange={() =>
                  this.setState({
                    savedLinksMenuOpen: !this.state.savedLinksMenuOpen,
                  })
                }
                icon={<FontAwesomeIcon icon={faUser} />}
                key="saved"
                title={this.state.sideBarCollapsed ? "" : "My Links"}
              >
                {_.orderBy(this.state.savedLinks, ["dateAdded"], "desc").map(
                  (i) => {
                    return (
                      <SavedItem
                        collapsed={this.state.sideBarCollapsed}
                        socket={this.props.socket}
                        focused={
                          this.props.focusedLink?.type === "saved" &&
                          this.props.focusedLink.link === i.link
                        }
                        onFocus={this.onFocus}
                        onDelete={this.onDeleteSavedLink}
                        key={`saved-${i.name}-${i.link}`}
                        pinnedLinks={this.state.pinnedLinks.map((l) => l.link)}
                        sharedLinks={this.state.sharedLinks.map((l) => l.link)}
                        name={i.name}
                        link={i.link}
                      />
                    );
                  }
                )}
              </SubMenu>
              {!this.props.adminVerified && !this.state.sideBarCollapsed ? (
                <div
                  style={{
                    padding: "5px",
                    display: "flexbox",
                  }}
                >
                  <PasswordInput
                    socket={this.props.socket}
                    onAdminVerified={this.onAdminVerified}
                  />
                </div>
              ) : (
                <span>
                  {this.props.adminVerified && (
                    <div
                      data-tip={
                        this.state.sideBarCollapsed ? "Logged in as Admin" : ""
                      }
                    >
                      <SubMenu
                        id="adminLabel"
                        title={
                          this.state.sideBarCollapsed
                            ? ""
                            : "Logged in as Admin"
                        }
                        style={{
                          marginLeft: this.state.sideBarCollapsed
                            ? "-13px"
                            : "inherit",
                        }}
                        icon={<FontAwesomeIcon icon={faHardHat} />}
                      />
                      {!this.state.sideBarCollapsed && (
                        <MDBBtn onClick={this.logState}>Log data</MDBBtn>
                      )}
                    </div>
                  )}
                </span>
              )}
            </Menu>
            {/* <ConnectedUsers
              connectedHashedUserTokens={this.props.connectedHashedUserTokens}
            /> */}
          </SidebarContent>
        </ProSidebar>
      </div>
    );
  }
}
