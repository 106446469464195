import React from "react";
import Sidebar from "../Sidebar";
import VirtualTab from "../VirtualTab";
import { JoinRoomRequest } from "../../../../common/requests";
import { RoomJoinedResponse } from "../../../../common/responses";
import { SocketClient } from "../../common/SocketClient";
import Loading from "../Loading";
import { LinkType } from "../../../../common/types";
import _ from "lodash";
import { Link } from "../../common/types";
import { DOCUMENTATION_URL } from "../../common/constants";

interface State {
  sidebarCollapsed: boolean;
  adminVerified: boolean;
  error: string;
  connectedToRoom: boolean;
  connectedHashedUserTokens: string[];
  focusedLink?: { type?: LinkType; link: string };
  slug: string;
  savedLinks: {
    dateAdded: Date;
    name: string;
    link: string;
  }[];
  sharedLinks: Link[];
  pinnedLinks: Link[];
}

interface Props {
  socket: SocketClient;
}
export default class LiveView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sidebarCollapsed: false,
      error: "",
      adminVerified: false,
      connectedToRoom: false,
      focusedLink: { link: DOCUMENTATION_URL, type: "documentation" },
      slug: "",
      connectedHashedUserTokens: [],
      savedLinks: [],
      pinnedLinks: [],
      sharedLinks: [],
    };
  }

  initializeState = () => {
    const adminPassword = localStorage.getItem("adminPassword") ?? "";
    const joinRoomRequest: JoinRoomRequest = {
      slug: this.state.slug,
      adminPassword,
    };
    this.props.socket.emit("joinRoom", joinRoomRequest);
    this.props.socket.on("roomJoined", (res: RoomJoinedResponse) => {
      this.setState({
        focusedLink: { link: DOCUMENTATION_URL, type: "documentation" },
        // focusedLink: res.room.globallyFocusedLink,
        adminVerified: res.adminVerified,
        connectedHashedUserTokens: res.room.connectedHashedUserTokens,
        connectedToRoom: true,
        slug: res.room.slug,
        sharedLinks: res.room.sharedLinks.map((x) => {
          return {
            hashedUserToken: x.hashedUserToken,
            link: x.link,
            name: x.name,
            dateAdded: x.dateAdded,
            new: false,
          };
        }),
        pinnedLinks: res.room.pinnedLinks.map((x) => {
          return {
            hashedUserToken: x.hashedUserToken,
            link: x.link,
            name: x.name,
            dateAdded: x.dateAdded,
            new: false,
          };
        }),
      });
      if (res.adminVerified) {
        document.title = "Link Hub - Admin";
      }
      // try {
      //   const newPinnedLinks: Link[] = JSON.parse(
      //     localStorage.getItem("newPinnedLinks") ?? ""
      //   );
      //   if (!newPinnedLinks) {
      //     localStorage.setItem(
      //       "newPinnedLinks",
      //       JSON.stringify(res.room.pinnedLinks.map((x) => x.link))
      //     );
      //   } else {
      //     console.log(_.difference(newPinnedLinks, res.room.pinnedLinks));
      //   }
      //   const newSharedLinks: Link[] = JSON.parse(
      //     localStorage.getItem("newSharedLinks") ?? ""
      //   );
      //   if (!newSharedLinks) {
      //     localStorage.setItem(
      //       "newSharedLinks",

      //       JSON.stringify(res.room.sharedLinks.map((x) => x.link))
      //     );
      //   }
      // } catch (e) {
      //   console.log(e);
      // }
    });
  };

  componentDidMount = () => {
    const slug =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    this.setState({ slug });
    try {
      this.populateSavedItems();
      this.props.socket.on("disconnect", () => {
        this.setState({ connectedToRoom: false });
      });
      this.props.socket.on("reconnect", () => {
        this.initializeState();
      });
      this.props.socket.on("connect", () => {
        this.initializeState();
      });
    } catch (e) {
      console.log(`Failed to join room`, e);
    }
  };

  onAdminVerified = () => {
    document.title = "Link Hub - Admin";
    this.setState({ adminVerified: true });
  };

  populateSavedItems = () => {
    const savedLinks: { name: string; link: string; dateAdded: Date }[] =
      //@ts-ignore
      JSON.parse(localStorage.getItem("links")) ?? [];
    this.setState({ savedLinks });
  };

  onFocus = (link: string, linkType: LinkType) => {
    this.setState({ focusedLink: { link, type: linkType } });
  };

  onCollapse = () => {
    this.setState({ sidebarCollapsed: !this.state.sidebarCollapsed });
  };

  onLinkUnshared = (defaultLink: string) => {
    this.setState({
      focusedLink: { link: defaultLink, type: "documentation" },
    });
  };

  render() {
    if (this.state.connectedToRoom) {
      return (
        <div>
          <Sidebar
            onLinkUnshared={this.onLinkUnshared}
            onCollapse={this.onCollapse}
            socket={this.props.socket}
            adminVerified={this.state.adminVerified}
            onAdminVerified={this.onAdminVerified}
            focusedLink={this.state.focusedLink}
            onFocus={this.onFocus}
            savedLinks={this.state.savedLinks}
            sharedLinks={this.state.sharedLinks}
            pinnedLinks={this.state.pinnedLinks}
            slug={this.state.slug}
            connectedHashedUserTokens={this.state.connectedHashedUserTokens}
          />
          <VirtualTab
            link={this.state.focusedLink?.link}
            sidebarCollapsed={this.state.sidebarCollapsed}
          />
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}
