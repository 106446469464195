import {
  faClipboard,
  faCopy,
  faPlus,
  faShareSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import ReactTooltip from "react-tooltip";
import { ShareLinkRequest, UnPinLinkRequest } from "../../../common/requests";
import { LinkType } from "../../../common/types";
import { SocketClient } from "../common/SocketClient";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getUserToken } from "../common/util";
// import * as bcrypt from "bcrypt";
interface Props {
  collapsed: boolean;
  socket: SocketClient;
  adminVerified: boolean;
  onFocus: Function;
  focused: boolean;
  hashedUserToken: string;
  onSave: Function;
  name: string;
  link: string;
  sharedLinks: { link: string; hashedUserToken: string }[];
  savedLinks: { link: string }[];
}

interface State {
  currentHashedUserToken: string;
}
export default class PinnedItem extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentHashedUserToken: getUserToken(),
    };
  }
  onShare = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const request: ShareLinkRequest = {
      name: this.props.name,
      link: this.props.link,
    };
    this.props.socket.emit("shareLink", request);
  };

  onSave = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onSave(this.props.name, this.props.link);
  };

  onDelete = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const adminPassword = localStorage.getItem("adminPassword") ?? "";
    const request: UnPinLinkRequest = { link: this.props.link, adminPassword };
    this.props.socket.emit("unPinLink", request);
  };

  onFocus = () => {
    const linkType: LinkType = "pinned";
    this.props.onFocus(this.props.link, linkType);
  };
  render() {
    return (
      <div
        onClick={this.onFocus}
        className={`namedLinkContainer ${this.props.focused && "focused"}`}
      >
        <ReactTooltip effect="solid" />
        <span
          className="createdItemText"
          data-tip={`${this.props.name} (${this.props.link})`}
        >
          {this.props.name}
        </span>
        {!this.props.collapsed &&
        <div
          className="buttonBar"
          style={{
            width: "60%",
            marginRight: "10px",
            marginLeft: "15%",
            display: "inline-flex",
          }}
        >
          <MDBBtn
          data-tip="Save"
          onClick={(e: MouseEvent) => this.onSave(e)}
          className="menuBtn"
          disabled={_.some(
            this.props.savedLinks,
            (l) => l.link === this.props.link
            )}
            >
            <FontAwesomeIcon icon={faPlus} />
          </MDBBtn>
          <MDBBtn
          data-tip="Share"
          disabled={_.some(
            this.props.sharedLinks,
            (l) => l.link === this.props.link
            )}
            onClick={(e: MouseEvent) => this.onShare(e)}
            className="menuBtn"
            >
            <FontAwesomeIcon icon={faShareSquare} />
          </MDBBtn>
          <CopyToClipboard text={this.props.link}>
            <MDBBtn data-tip="Copy Link" className="menuBtn">
              <FontAwesomeIcon icon={faCopy} />
            </MDBBtn>
          </CopyToClipboard>
          {/* {(bcrypt.compareSync(
            this.props.hashedUserToken,
            this.state.currentHashedUserToken
          ) || */}
            {/* this.props.adminVerified) && ( */}
            <MDBBtn
              data-tip="Delete"
              onClick={(e: MouseEvent) => this.onDelete(e)}
              className="menuBtn"
            >
              <FontAwesomeIcon icon={faTrash} />
            </MDBBtn>
          {/* )} */}
        </div>
          }
      </div>
    );
  }
}
