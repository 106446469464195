import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import React from "react";
import { CheckPasswordRequest } from "../../../common/requests";
import { CorrectPasswordResponse } from "../../../common/responses";
import { SocketClient } from "../common/SocketClient";

interface State {
  password: string;
  errorMessage: string;
}

interface Props {
  socket: SocketClient;
  onAdminVerified: Function;
}

export default class PasswordInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      password: "",
      errorMessage: "",
    };

    this.props.socket.on(
      "correctPassword",
      (response: CorrectPasswordResponse) => {
        localStorage.setItem("adminPassword", response.password);
        this.props.onAdminVerified();
        this.setState({ errorMessage: "", password: "" });
      }
    );

    this.props.socket.on(
      "incorrectPassword",
      (response: CorrectPasswordResponse) => {
        this.setState({ errorMessage: "Incorrect Password", password: "" });
      }
    );
  }

  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      password: e.currentTarget.value,
    });
  };

  onSubmit = () => {
    const request: CheckPasswordRequest = { password: this.state.password };
    this.props.socket.emit("checkPassword", request);
  };

  render() {
    return (
      <div>
        <MDBInput
          labelStyle={{ color: "#adadad" }}
          label="Admin password"
          value={this.state.password}
          onChange={this.handleChange}
        />
        <div style={{ marginTop: "10px" }}>
          <MDBBtn onClick={this.onSubmit}>Submit</MDBBtn>
          <span style={{ marginLeft: "10px" }}>{this.state.errorMessage}</span>
        </div>
      </div>
    );
  }
}
