import {
  MDBBtn,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBInput,
} from "mdb-react-ui-kit";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbtack,
  faShare,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { PinLinkRequest, ShareLinkRequest } from "../../../common/requests";
import isUrl from "is-url";
import { IntegrationLinks } from "../common/constants";
import * as uuid from "uuid";
import { SocketClient } from "../common/SocketClient";

const INITIAL_SAVE_TIP = "Save";
const INITIAL_SHARE_TIP = "Share";
const INITIAL_PIN_TIP = "Pin";

interface Props {
  socket: SocketClient;
  onSave: Function;
  savedLinks: { name: string; link: string }[];
  sharedItems: { name: string; link: string }[];
  pinnedLinks: { name: string; link: string }[];
}

interface State {
  name: string;
  link: string;
  saveButtonDisabled: boolean;
  shareButtonDisabled: boolean;
  pinButtonDisabled: boolean;
  saveButtonTip: string;
  shareButtonTip: string;
  pinButtonTip: string;
  linkInputError: string;
}

export default class LinkInputCard extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: "",
      link: "",
      saveButtonDisabled: true,
      shareButtonDisabled: true,
      pinButtonDisabled: true,
      saveButtonTip: INITIAL_SAVE_TIP,
      shareButtonTip: INITIAL_SHARE_TIP,
      pinButtonTip: INITIAL_PIN_TIP,
      linkInputError: "",
    };
  }

  saveLink = () => {
    if (this.state.link.length > 0 && this.state.name.length > 0) {
      this.setState({ name: "", link: "" }, this.determineDisabledButtonsState);
      this.props.onSave(this.state.name, this.state.link);
    }
  };

  shareLink = () => {
    if (this.state.link.length > 0 && this.state.name.length > 0) {
      this.determineDisabledButtonsState();
      const request: ShareLinkRequest = {
        name: this.state.name,
        link: this.state.link,
      };
      this.props.socket.emit("shareLink", request);
      this.props.socket.on("shareLink", () => {
        this.setState({ name: "", link: "" });
        this.determineDisabledButtonsState();
      });
    }
  };

  pinLink = () => {
    if (this.state.link.length > 0 && this.state.name.length > 0) {
      this.determineDisabledButtonsState();
      const request: PinLinkRequest = {
        name: this.state.name,
        link: this.state.link,
      };
      this.props.socket.emit("pinLink", request);
      this.props.socket.on("pinLink", () => {
        this.setState({ name: "", link: "" });
        this.determineDisabledButtonsState();
      });
    }
  };

  handleValidation = () => {
    if (isUrl(this.state.link)) {
      this.setState({ linkInputError: "Invalid link" });
    }
  };

  addIntegrationLink = (incomingLink: string) => {
    const link = incomingLink + uuid.v4();
    this.setState({ link }, () => {
      this.determineDisabledButtonsState();
    });
  };

  determineDisabledButtonsState = () => {
    const savedItemExists = this.props.savedLinks.some(
      (i) => i.link === this.state.link || i.name === this.state.name
    );
    const sharedItemExists = this.props.sharedItems.some(
      (i) => i.link === this.state.link || i.name === this.state.name
    );
    const pinnedItemExists = this.props.pinnedLinks.some(
      (i) => i.link === this.state.link || i.name === this.state.name
    );
    this.setState({
      saveButtonDisabled:
        this.state.link.length === 0 ||
        this.state.name.length === 0 ||
        savedItemExists,
      saveButtonTip: savedItemExists ? "Already exists" : INITIAL_SAVE_TIP,
      shareButtonDisabled:
        this.state.link.length === 0 ||
        this.state.name.length === 0 ||
        sharedItemExists,
      shareButtonTip: sharedItemExists ? "Already exists" : INITIAL_SHARE_TIP,
      pinButtonDisabled:
        this.state.link.length === 0 ||
        this.state.name.length === 0 ||
        pinnedItemExists,
      pinButtonTip: pinnedItemExists ? "Already exists" : INITIAL_PIN_TIP,
    });
  };

  handleLinkInput = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState(
      { link: e.currentTarget.value },
      this.determineDisabledButtonsState
    );
  };

  handleNameInput = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState(
      { name: e.currentTarget.value },
      this.determineDisabledButtonsState
    );
  };

  render() {
    return (
      <div>
        <div className="form-group">
          <ReactTooltip effect="solid" />
          <MDBInput
            labelStyle={{ color: "#adadad" }}
            className="dataInput"
            style={{ marginTop: "50px" }}
            label="Name"
            onChange={this.handleNameInput}
            value={this.state.name}
          />
          <MDBInput
            focused
            labelStyle={{ color: "#adadad" }}
            className="dataInput"
            label="Link"
            style={{ marginTop: "0.5rem" }}
            onChange={this.handleLinkInput}
            value={this.state.link}
          />
        </div>
        <div className="btnGroup">
          <div style={{ marginTop: "1rem", marginLeft: "16%" }}>
            <MDBDropdown>
              <MDBDropdownToggle>Integration</MDBDropdownToggle>
              <MDBDropdownMenu>
                {IntegrationLinks.map((l) => {
                  return (
                    <MDBDropdownItem>
                      <MDBDropdownLink
                        onClick={() => {
                          this.addIntegrationLink(l.link);
                        }}
                        tag="button"
                        type="button"
                      >
                        {l.name}
                      </MDBDropdownLink>
                    </MDBDropdownItem>
                  );
                })}
              </MDBDropdownMenu>
            </MDBDropdown>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <MDBBtn
              data-tip={this.state.saveButtonTip}
              className="linkActionBtn"
              disabled={this.state.saveButtonDisabled}
              onClick={this.saveLink}
              color="primary"
            >
              <FontAwesomeIcon icon={faSave} />
            </MDBBtn>
            <MDBBtn
              data-tip={this.state.shareButtonTip}
              className="linkActionBtn"
              disabled={this.state.shareButtonDisabled}
              onClick={this.shareLink}
              color="primary"
            >
              <FontAwesomeIcon icon={faShare} />
            </MDBBtn>
            <MDBBtn
              data-tip={this.state.pinButtonTip}
              className="linkActionBtn"
              disabled={this.state.pinButtonDisabled}
              onClick={this.pinLink}
              color="primary"
            >
              <FontAwesomeIcon icon={faThumbtack} />
            </MDBBtn>
          </div>
        </div>
      </div>
    );
  }
}
