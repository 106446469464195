import { LocalStorageLinkItem } from "../../../common/types";

export const getSavedLinks = (): LocalStorageLinkItem[] => {
  const links: LocalStorageLinkItem[] = JSON.parse(
    //@ts-ignore
    localStorage.getItem("links")
  );
  return links;
};

export const getUserToken = (): string => {
  const token = localStorage.getItem("hashedUserToken") ?? "";
  return token;
};
