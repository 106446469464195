import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faChessKnight,
  faFish,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";

export const DOCUMENTATION_URL =
  "https://docs.google.com/document/d/e/2PACX-1vT-GA-oXcVhOG1naUdW6RjHwwy9MBs9jJ8je2iF8BW1ivlfBqCTsftUaqKu7CWDVrC4loZV3-TN9Ev6/pub";
// embed - "https://docs.google.com/document/d/e/2PACX-1vT-GA-oXcVhOG1naUdW6RjHwwy9MBs9jJ8je2iF8BW1ivlfBqCTsftUaqKu7CWDVrC4loZV3-TN9Ev6/pub?embedded=true";

export const IntegrationLinks: {
  emoji?: IconDefinition;
  name: string;
  link: string;
  needsCode: boolean;
}[] = [
  {
    name: "Whiteboard",
    needsCode: true,
    emoji: faPencilAlt,
    link: `https://app.tryeraser.com/integration/gather/`,
  },
  {
    name: "Chess",
    needsCode: true,
    emoji: faChessKnight,
    link: `https://rootshirechess.glitch.me/?view=room&roomname=`,
  },
  {
    name: "Sudoku",
    needsCode: true,
    link: `https://sudoku.gather.town/room/`,
  },
  {
    name: "Fishbowl",
    needsCode: false,
    emoji: faFish,
    link: `https://fishbowl-game.com/`,
  },
  {
    name: "Tetris",
    needsCode: false,
    link: `https://jstris.jezevec10.com/?join=`,
  },
  {
    name: "Codewords",
    needsCode: false,
    link: "https://netgames.io/games/codewords/",
  },
  {
    name: "Pictionary",
    needsCode: false,
    link: "https://gather.town/skribbl",
  },
];
